import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

type HeadingColor = 'dark' | 'bright';
type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LetDirective, NgClass, NgTemplateOutlet],
})
export class HeadingComponent {
  @Input()
  color: HeadingColor = 'dark';

  @Input()
  level: HeadingLevel = 1;

  @Input()
  displayLevel: HeadingLevel | undefined;
}
